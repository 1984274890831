@use '../abstracts/colors' as c;
@use '../abstracts/variables' as v;

$footer-background-color: c.$background-color-second;
$text-color: #ffffff46;

$social-main-bgc: c.$primary-color;
$social-hover-bgc: hsl(255, 93%, 72%);

.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  padding: 1.5rem 1em;
  background-color: $footer-background-color;

  &__logo {
    margin-left: 0.5em;
    line-height: 0.8;
    font-size: 2rem;
    font-size: clamp(2rem, 1rem + 1.5vw, 3.5rem);
  }

  p {
    color: $text-color;
    font-size: clamp(1.2rem, 0.3rem + 0.6vw, 1.4rem);
  }

  &__links {
    display: flex;
    align-items: center;
    gap: 0.75em;
    margin: 1.5rem 0 2rem;
    font-size: clamp(2rem, 1rem + 1vw, 3rem);
  }

  &__link {
    box-sizing: content-box;
    position: relative;
    z-index: 0;
    width: 2em;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: background-color v.$transition-duration v.$transition-function;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $social-main-bgc;
      border-radius: 50%;
    }
    &::after {
      content: '';
      position: absolute;
      z-index: -2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $social-hover-bgc;
      border-radius: 50%;
      transition: transform v.$transition-duration v.$transition-function;
    }

    span {
      position: absolute;
      top: 75%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 0.3em;
      text-transform: uppercase;
      opacity: 0;
      transition: opacity v.$transition-duration v.$transition-function;
    }

    .icon {
      width: 1em;
      height: 1em;

      transition:
        transform v.$transition-duration v.$transition-function,
        opacity v.$transition-duration v.$transition-function;
    }

    &:hover,
    &:focus {
      &::after {
        transform: scale(1.3);
      }
      span {
        opacity: 0.7;
      }
      .icon {
        transform: scale(0.8) translateY(-25%);
        opacity: 0.8;
      }
    }
  }
}
