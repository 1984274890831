@use '../abstracts/colors' as c;
@use '../abstracts/breakpoints' as b;

html {
  font-size: 62.5%;
}

:root {
  --font-size: 1.4rem;
}

body {
  color: c.$font-color;
  font-family: 'Roboto', sans-serif;
}

p {
  font-size: 1.4rem;
  font-size: clamp(1.4rem, 1rem + 0.35vw, 1.8rem);
  font-weight: 400;
}
