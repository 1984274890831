@use '../abstracts/colors' as c;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: c.$background-color;
  overflow-x: clip;
}

ul,
ol {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}
button {
  border: transparent;
  background-color: transparent;
}

img {
  width: 100%;
  display: block;
}
