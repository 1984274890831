@use '../abstracts/breakpoints' as b;
@use '../abstracts/colors' as c;

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  padding: 4rem 1rem;
  @media screen and (min-width: b.$medium) {
    gap: 6rem;
    padding: 8rem 1rem;
  }

  &--left {
    @extend .section;
    @media screen and (min-width: b.$medium) {
      align-items: start;
    }
  }

  &--small-gap {
    @media screen and (min-width: b.$medium) {
      gap: 4rem;
    }
  }

  &__title {
    font-size: 2rem;
    font-size: clamp(2.5rem, 1rem + 1.5vw, 4rem);
    font-family: 'Kode Mono', monospace;
    color: c.$font-color;

    span {
      color: c.$primary-color;
    }
  }

  &__text {
    letter-spacing: 1px;
    opacity: 0.8;
    line-height: 1.6;
    font-weight: 400;
  }

  &__content {
    width: 100%;
  }
}
