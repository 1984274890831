@use '../abstracts/colors' as c;
@use '../abstracts/breakpoints' as b;
@use '../abstracts/variables' as v;

$nav-border-radious: v.$border-radious;
$nav-mobile-background-color: rgba(15, 15, 15, 0.6);
$nav-hamburger-bg: rgba(0, 0, 0, 0.3);
$nav-hamburger-border: 1px solid rgba(255, 255, 255, 0.15);

$nav-font-family: 'Roboto', sans-serif;
$nav-font-family-span: 'Kode Mono', monospace;
$nav-font-color: c.$font-color;
$nav-font-weight: 400;
$nav-font-size: 14px;

$nav-span-color: c.$font-color-darker-2;

.nav {
  position: fixed;
  z-index: 999;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  color: $nav-font-color;
  font-family: $nav-font-family;
  font-size: $nav-font-size;
  font-weight: $nav-font-weight;

  &--bgc {
    // background-color: c.$background-color;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: c.$background-color;

      opacity: 0.9;
    }
  }

  @media screen and (min-width: b.$medium) {
    padding: 1rem 2rem;
  }

  &__list {
    position: absolute;
    z-index: 500;
    right: 0;
    top: 5em;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    padding: 1.5em 1em;
    margin-right: 1.5rem;
    background-color: $nav-mobile-background-color;
    border-radius: $nav-border-radious;
    opacity: 0;
    transform: translateY(25%);
    transition:
      opacity 0.5s ease,
      transform 1.2s ease;
    pointer-events: none;

    &.active {
      opacity: 1;
      pointer-events: all;
      backdrop-filter: blur(6px);
      transform: translateY(0);
    }

    @media screen and (min-width: b.$medium) {
      position: static;
      flex-direction: row;
      align-items: center;
      gap: 2em;
      padding: 1em 0;
      background-color: unset;
      opacity: 1;
      pointer-events: all;
      transform: translateY(0);
    }
  }

  &__link {
    position: relative;
    display: flex;
    flex-direction: column;
    letter-spacing: -0.02em;
    line-height: 1.2;
    text-align: left;

    &::before {
      content: '';
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: c.$primary-color;
      box-shadow:
        0 0 3px c.$primary-color,
        0 0 6px c.$primary-color,
        0 0 9px c.$primary-color;
      transition: opacity v.$transition-duration v.$transition-function;
      opacity: 0;
      pointer-events: none;
    }

    span {
      margin-bottom: 0.1em;
      color: $nav-span-color;
      font-size: 0.8em;
      font-family: $nav-font-family-span;
      transition: color v.$transition-duration v.$transition-function;
    }

    &:hover,
    &:focus-within,
    &.active {
      span {
        color: c.$primary-color;
      }

      &::before {
        opacity: 0.6;
      }
    }
  }

  li:has(&__btn-resume) {
    display: none;

    @media screen and (min-width: b.$medium) {
      display: inline-block;
    }
  }

  &__btn-hamburger-box {
    width: 4.5rem;
    height: 4.5rem;
    background-color: $nav-hamburger-bg;
    border-radius: $nav-border-radious;
    border: $nav-hamburger-border;
    backdrop-filter: blur(6px);
    @media screen and (min-width: b.$medium) {
      display: none;
    }
  }
  &__btn-hamburger {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      pointer-events: none;
      transition-duration: 1s;
      &::before {
        width: 75%;
        opacity: 75%;
      }
      &::after {
        width: 50%;
        opacity: 50%;
      }
    }

    &.is-active {
      span {
        &::before,
        &::after {
          width: 100%;
          opacity: 100%;
        }
      }
    }
  }
}
