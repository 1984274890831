// Hamburger Button for mobile devices
$hamburger-padding-x: 0px !default;
$hamburger-padding-y: 0px !default;
$hamburger-layer-width: 20px !default;
$hamburger-layer-height: 2px !default;
$hamburger-layer-spacing: 4px !default;
$hamburger-layer-color: #fff !default;
$hamburger-layer-border-radius: 4px !default;
$hamburger-hover-opacity: 0.7 !default;
$hamburger-active-layer-color: $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

// Types (Remove or comment out what you don’t need)
$hamburger-types: (
  3dx,
  3dx-r,
  3dy,
  3dy-r,
  3dxy,
  3dxy-r,
  arrow,
  arrow-r,
  arrowalt,
  arrowalt-r,
  arrowturn,
  arrowturn-r,
  boring,
  collapse,
  collapse-r,
  elastic,
  elastic-r,
  emphatic,
  emphatic-r,
  minus,
  slider,
  slider-r,
  spring,
  spring-r,
  stand,
  stand-r,
  spin,
  spin-r,
  squeeze,
  vortex,
  vortex-r
) !default;

@import '../../../node_modules/hamburgers/_sass/hamburgers/base';
@import '../../../node_modules/hamburgers/_sass/hamburgers/types/spring';
