@use '../abstracts/colors' as c;

main {
  position: relative;
  z-index: 0;
  min-height: 100vh;
  padding: 10rem 0;
  padding: 5vw 0;
  background-image: linear-gradient(
    to top,
    rgba(c.$background-color, 1) 97%,
    rgba(c.$background-color, 0.5) 100%
  );
  background-image: linear-gradient(
    to bottom,
    rgba(c.$background-color, 0.1) 5px,
    rgba(c.$background-color, 0.5) 35px,
    rgba(c.$background-color, 1) 70px
  );

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../img/bg.webp');
    background-position: 50% 85%;
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;
  }
}
