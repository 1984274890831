:root {
  --primary-color: #7850f2;
  --font-color-white: #fff;
  --font-color-darker: #ffffffc5;
  --font-color-darker-2: #ffffff8c;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #100e17;
  overflow-x: clip;
}

ul, ol {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  background-color: #0000;
  border: #0000;
}

img {
  width: 100%;
  display: block;
}

html {
  font-size: 62.5%;
}

:root {
  --font-size: 1.4rem;
}

body {
  color: var(--font-color-white);
  font-family: Roboto, sans-serif;
}

p {
  font-size: clamp(1.4rem, 1rem + .35vw, 1.8rem);
  font-weight: 400;
}

.icon {
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  width: 2em;
  height: 2em;
  display: inline-block;
}

.wrapper-12 {
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
}

.wrapper-14 {
  width: 100%;
  max-width: 140rem;
  margin: 0 auto;
}

.scroll-margin {
  scroll-margin: 3rem;
}

.scroll-margin-large {
  scroll-margin: 25rem;
}

.hamburger {
  cursor: pointer;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: #0000;
  border: 0;
  margin: 0;
  padding: 0;
  transition-property: opacity, filter;
  transition-duration: .15s;
  transition-timing-function: linear;
  display: inline-block;
  overflow: visible;
}

.hamburger:hover, .hamburger.is-active:hover {
  opacity: .7;
}

.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner:before, .hamburger.is-active .hamburger-inner:after {
  background-color: #fff;
}

.hamburger-box {
  width: 20px;
  height: 14px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  margin-top: -1px;
  display: block;
  top: 50%;
}

.hamburger-inner, .hamburger-inner:before, .hamburger-inner:after {
  background-color: #fff;
  border-radius: 4px;
  width: 20px;
  height: 2px;
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: ease;
  position: absolute;
}

.hamburger-inner:before, .hamburger-inner:after {
  content: "";
  display: block;
}

.hamburger-inner:before {
  top: -6px;
}

.hamburger-inner:after {
  bottom: -6px;
}

.hamburger--spring .hamburger-inner {
  transition: background-color 0s linear .13s;
  top: 1px;
}

.hamburger--spring .hamburger-inner:before {
  transition: top .1s cubic-bezier(.33333, .66667, .66667, 1) .2s, transform .13s cubic-bezier(.55, .055, .675, .19);
  top: 6px;
}

.hamburger--spring .hamburger-inner:after {
  transition: top .2s cubic-bezier(.33333, .66667, .66667, 1) .2s, transform .13s cubic-bezier(.55, .055, .675, .19);
  top: 12px;
}

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: .22s;
  background-color: #0000 !important;
}

.hamburger--spring.is-active .hamburger-inner:before {
  transition: top .1s cubic-bezier(.33333, 0, .66667, .33333) .15s, transform .13s cubic-bezier(.215, .61, .355, 1) .22s;
  top: 0;
  transform: translate3d(0, 6px, 0)rotate(45deg);
}

.hamburger--spring.is-active .hamburger-inner:after {
  transition: top .2s cubic-bezier(.33333, 0, .66667, .33333), transform .13s cubic-bezier(.215, .61, .355, 1) .22s;
  top: 0;
  transform: translate3d(0, 6px, 0)rotate(-45deg);
}

.btn, .btn--secondary, .btn--primary {
  z-index: 1;
  color: #eee;
  text-transform: capitalize;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: .75em;
  padding: .7em 1.2em;
  font-family: Roboto, sans-serif;
  font-size: clamp(1.3rem, .7rem + .5vw, 1.5rem);
  font-weight: 500;
  transition: all .5s;
  display: flex;
  position: relative;
}

.btn .icon, .btn--secondary .icon, .btn--primary .icon {
  aspect-ratio: 1;
  fill: currentColor;
  width: 1.5em;
}

.btn--primary {
  background-color: var(--primary-color);
  border-radius: 3px;
  outline: #0000;
}

.btn--primary:before, .btn--primary:after {
  box-sizing: inherit;
  content: "";
  z-index: -1;
  border: 2px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
}

.btn--primary:before {
  top: 0;
  left: 0;
}

.btn--primary:after {
  bottom: 0;
  right: 0;
}

.btn--primary:hover, .btn--primary:focus {
  color: var(--primary-color);
  background: none;
}

.btn--primary:hover:before, .btn--primary:hover:after, .btn--primary:focus:before, .btn--primary:focus:after {
  width: 100%;
  height: 100%;
}

.btn--primary:hover:before, .btn--primary:focus:before {
  border-top-color: var(--primary-color);
  border-right-color: var(--primary-color);
  transition: width .25s ease-out, height .25s ease-out .25s;
}

.btn--primary:hover:after, .btn--primary:focus:after {
  border-bottom-color: var(--primary-color);
  border-left-color: var(--primary-color);
  transition: border-color 0s ease-out .5s, width .25s ease-out .5s, height .25s ease-out .75s;
}

.btn--secondary:before {
  content: "";
  background-color: var(--primary-color);
  width: calc(105% - 2.4em);
  height: 1px;
  margin: 0 auto;
  transition: transform .3s ease-in-out, box-shadow .3s ease-in-out;
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translateX(-50%);
}

.btn--secondary:hover:before {
  box-shadow: 0 -5px 10px var(--primary-color), 0 -10px 15px var(--primary-color), 0 -15px 25px var(--primary-color);
  transform: translateX(-50%)scaleY(2)scaleX(1.2);
}

.logo {
  text-transform: capitalize;
  font-family: Homemade Apple, cursive;
  font-size: 1.5em;
  line-height: 1;
  position: relative;
  overflow: hidden;
}

.logo span {
  color: var(--primary-color);
  font-size: 2em;
}

.logo .icon {
  aspect-ratio: 1;
  fill: var(--primary-color);
  width: 1.5em;
}

.logo:before {
  content: "";
  background-color: var(--primary-color);
  transform-origin: 0;
  width: 100%;
  height: 2px;
  transition: transform .3s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
}

.logo:hover:before, .logo:focus:before {
  transform: scaleX(1);
}

.mouse-scroll {
  display: none;
}

@media screen and (min-width: 48em) {
  .mouse-scroll {
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    display: flex;
    position: absolute;
    bottom: 3%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.mouse-scroll .text {
  color: #fff3;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: Kode Mono, sans-serif;
  font-size: 1rem;
}

.mouse-scroll .mouse-outer {
  border: 2px solid #ffffff80;
  border-radius: 40px;
  width: 20px;
  height: 40px;
  transition: border-color .2s ease-in-out;
  display: inline-block;
  position: relative;
}

.mouse-scroll .mouse-outer .mouse-wheel {
  background-color: #ffffff80;
  border-radius: 4px;
  width: 4px;
  height: 10px;
  margin-left: -1.66667px;
  transition: background-color .2s ease-in-out;
  animation: 1.4s linear infinite scroll;
  position: absolute;
  top: 10%;
  left: 50%;
}

.mouse-scroll .mouse-outer:hover {
  border-color: var(--primary-color);
}

.mouse-scroll .mouse-outer:hover .mouse-wheel {
  background-color: var(--primary-color);
}

@keyframes scroll {
  0% {
    transform: scaleY(.5)translateY(0);
  }

  20% {
    transform: scaleY(1)translateY(5px);
  }

  60% {
    transform: scaleY(.5)translateY(15px);
  }

  to {
    transform: scaleY(.5)translateY(0);
  }
}

.section, .section--left {
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  padding: 4rem 1rem;
  display: flex;
}

@media screen and (min-width: 48em) {
  .section, .section--left {
    gap: 6rem;
    padding: 8rem 1rem;
  }

  .section--left {
    align-items: start;
  }

  .section--small-gap {
    gap: 4rem;
  }
}

.section__title {
  color: var(--font-color-white);
  font-family: Kode Mono, monospace;
  font-size: clamp(2.5rem, 1rem + 1.5vw, 4rem);
}

.section__title span {
  color: var(--primary-color);
}

.section__text {
  letter-spacing: 1px;
  opacity: .8;
  font-weight: 400;
  line-height: 1.6;
}

.section__content {
  width: 100%;
}

.nav {
  z-index: 999;
  color: var(--font-color-white);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  position: fixed;
}

.nav--bgc:before {
  content: "";
  opacity: .9;
  background-color: #100e17;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (min-width: 48em) {
  .nav {
    padding: 1rem 2rem;
  }
}

.nav__list {
  z-index: 500;
  opacity: 0;
  pointer-events: none;
  background-color: #0f0f0f99;
  border-radius: 10px;
  flex-direction: column;
  gap: 1.5em;
  margin-right: 1.5rem;
  padding: 1.5em 1em;
  transition: opacity .5s, transform 1.2s;
  display: flex;
  position: absolute;
  top: 5em;
  right: 0;
  transform: translateY(25%);
}

.nav__list.active {
  opacity: 1;
  pointer-events: all;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  transform: translateY(0);
}

@media screen and (min-width: 48em) {
  .nav__list {
    background-color: unset;
    opacity: 1;
    pointer-events: all;
    flex-direction: row;
    align-items: center;
    gap: 2em;
    padding: 1em 0;
    position: static;
    transform: translateY(0);
  }
}

.nav__link {
  letter-spacing: -.02em;
  text-align: left;
  flex-direction: column;
  line-height: 1.2;
  display: flex;
  position: relative;
}

.nav__link:before {
  content: "";
  background-color: var(--primary-color);
  box-shadow: 0 0 3px var(--primary-color), 0 0 6px var(--primary-color), 0 0 9px var(--primary-color);
  opacity: 0;
  pointer-events: none;
  width: 100%;
  height: 1px;
  transition: opacity .3s ease-in-out;
  position: absolute;
  bottom: -4px;
  left: 0;
}

.nav__link span {
  color: var(--font-color-darker-2);
  margin-bottom: .1em;
  font-family: Kode Mono, monospace;
  font-size: .8em;
  transition: color .3s ease-in-out;
}

.nav__link:hover span, .nav__link:focus-within span, .nav__link.active span {
  color: var(--primary-color);
}

.nav__link:hover:before, .nav__link:focus-within:before, .nav__link.active:before {
  opacity: .6;
}

li:has(.nav__btn-resume) {
  display: none;
}

@media screen and (min-width: 48em) {
  li:has(.nav__btn-resume) {
    display: inline-block;
  }
}

.nav__btn-hamburger-box {
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  background-color: #0000004d;
  border: 1px solid #ffffff26;
  border-radius: 10px;
  width: 4.5rem;
  height: 4.5rem;
}

@media screen and (min-width: 48em) {
  .nav__btn-hamburger-box {
    display: none;
  }
}

.nav__btn-hamburger {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.nav__btn-hamburger span {
  pointer-events: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition-duration: 1s;
  display: flex;
}

.nav__btn-hamburger span:before {
  opacity: .75;
  width: 75%;
}

.nav__btn-hamburger span:after {
  opacity: .5;
  width: 50%;
}

.nav__btn-hamburger.is-active span:before, .nav__btn-hamburger.is-active span:after {
  opacity: 1;
  width: 100%;
}

.header {
  justify-content: start;
  align-items: center;
  height: 100vh;
  padding: 1em;
  display: flex;
  position: relative;
}

@media screen and (min-width: 48em) {
  .header {
    justify-content: center;
  }
}

.header:before {
  content: "";
  z-index: -2;
  background-color: #050505;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.header .myphoto {
  z-index: -1;
  opacity: .4;
  filter: grayscale();
  background-image: url("../img/my photo--mobile.6f71e7be.jpeg");
  background-position: top;
  background-size: cover;
  width: 100%;
  height: 90%;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media screen and (min-width: 48em) {
  .header .myphoto {
    aspect-ratio: 1.8;
    opacity: .1;
    background-image: url("../img/my photo.06121363.jpeg");
    height: 100%;
    transition: opacity .15s linear;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.header .myphoto:before {
  content: "";
  background-color: #050505;
  width: 100%;
  height: 5%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
  box-shadow: 0 0 30px 30px #050505, 0 0 15px 10px #050505;
}

.header__heading-box {
  position: relative;
}

.header__heading {
  text-transform: uppercase;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  font-family: Roboto, sans-serif;
  font-size: clamp(8rem, 6rem + 10vw, 30rem);
  font-weight: 500;
  display: flex;
  overflow: hidden;
}

.header__heading span {
  word-spacing: -10px;
  line-height: 1;
}

@media screen and (min-width: 48em) {
  .header__heading span {
    text-align: center;
    word-spacing: normal;
    line-height: normal;
    transition: transform .15s linear;
    display: block;
  }
}

.header__heading .text-stroke-mobile {
  color: #0000;
  text-shadow: 0 0 1px #0a0a0a, 0 0 2px var(--primary-color), 0 0 4px var(--primary-color);
  font-size: clamp(5rem, 4rem + 8vw, 20rem);
}

@media screen and (min-width: 48em) {
  .header__heading .text-stroke-mobile {
    display: none;
  }
}

.header__heading .text-stroke-mobile span {
  color: var(--primary-color);
}

.header__heading .text-stroke-desktop {
  display: none;
}

@media screen and (min-width: 48em) {
  .header__heading .text-stroke-desktop {
    letter-spacing: 10px;
    fill: #0000;
    stroke: var(--primary-color);
    stroke-width: 1px;
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    margin: .5em auto 0;
    font-size: clamp(5rem, 2rem + 8vw, 10rem);
    transition: transform .15s linear;
    animation: 4s linear both textStrokeAnim;
    display: block;
  }
}

.header__subheading-box {
  display: none;
}

@media screen and (min-width: 48em) {
  .header__subheading-box {
    color: #fff3;
    text-transform: uppercase;
    justify-content: space-between;
    padding: 0 1.5rem;
    font-family: Kdam Thmor Pro, sans-serif;
    transition: opacity .15s linear;
    display: flex;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
  }

  .header__subheading-box p {
    font-size: clamp(2rem, 1rem + .7vw, 3rem);
  }
}

.header__anime-line {
  flex-grow: 1;
  display: block;
  position: relative;
  overflow: hidden;
}

.header__anime-line:before {
  content: "";
  opacity: .4;
  background-color: var(--primary-color);
  border-radius: 500px;
  width: 50px;
  height: 3px;
  animation: 5s ease-in-out infinite alternate traveling-line;
  position: absolute;
  top: 50%;
  left: -50px;
  transform: translate(-50%, -50%);
}

.header__myname {
  animation: 5s linear infinite alternate-reverse pulse-color;
}

.header__job-role {
  animation: 5s linear infinite alternate pulse-color;
}

@keyframes textStrokeAnim {
  0% {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    fill: #0000;
  }

  95% {
    stroke-dasharray: 0%;
    stroke-dashoffset: 90%;
    fill: #0000;
  }

  100% {
    stroke-dasharray: 0%;
    stroke-dashoffset: 0%;
    fill: #0000;
    filter: drop-shadow(2px 2px 10px #0007);
  }
}

@keyframes pulse-color {
  75% {
    color: #fff3;
  }

  100% {
    color: var(--primary-color);
  }
}

@keyframes traveling-line {
  0% {
    left: -50px;
  }

  100% {
    left: calc(100% + 50px);
  }
}

main {
  z-index: 0;
  background-image: linear-gradient(#100e171a 5px, #100e1780 35px, #100e17 70px);
  min-height: 100vh;
  padding: 5vw 0;
  position: relative;
}

main:before {
  content: "";
  z-index: -1;
  background-image: url("../img/bg.99ad8709.webp");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.motto {
  color: var(--font-color-darker);
  letter-spacing: 1px;
  text-align: center;
  font-size: clamp(2.2rem, 1rem + 1.5vw, 3rem);
  font-style: italic;
}

.motto [data-type-text="true"] {
  position: relative;
}

.motto [data-type-text="true"]:after {
  content: "|";
  color: var(--primary-color);
  font-style: normal;
  position: absolute;
}

.motto .icon {
  color: var(--primary-color);
  font-size: .3em;
  transition: opacity .3s ease-in-out;
}

.motto .icon:first-child {
  vertical-align: bottom;
}

.motto .icon:last-child {
  vertical-align: top;
}

.about {
  flex-direction: column;
  gap: 4rem;
  display: flex;
}

@media screen and (min-width: 48em) {
  .about {
    flex-direction: row;
    gap: clamp(8rem, 8vw, 15rem);
  }

  .about__btn-resume {
    display: none;
  }
}

.about__info-box {
  flex-direction: column;
  gap: 2em;
  display: flex;
}

.about__info-box p {
  color: var(--font-color-darker);
  line-height: 1.5;
}

.about__info-box .strong {
  color: var(--primary-color);
  font-weight: 600;
}

.about__techs {
  flex-direction: column;
  gap: 2em;
  display: flex;
}

@media screen and (min-width: 48em) {
  .about__techs {
    max-width: 90%;
  }
}

.about__img-box {
  display: none;
}

@media screen and (min-width: 48em) {
  .about__img-box {
    aspect-ratio: 1;
    width: 100%;
    max-width: 35rem;
    max-height: 35rem;
    transition: box-shadow .3s ease-in-out;
    animation: 15s linear infinite backwards morph-photo;
    display: inline-block;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 15px #ffffff1a;
  }

  .about__img-box:hover {
    box-shadow: 0 0 25px #ffffff26;
  }

  .about__img-box:hover img {
    transform: scale(1.1);
  }

  .about__img-box img {
    object-fit: cover;
    object-position: center;
    min-height: 100%;
    transition: transform .3s ease-in-out;
  }
}

@keyframes morph-photo {
  0% {
    border-radius: 60% 40% 50% 50% / 60% 50% 50% 40%;
  }

  25% {
    border-radius: 49% 51% 65% 35% / 40% 60%;
  }

  50% {
    border-radius: 50% 50% 60% 40% / 50% 40% 60% 50%;
  }

  75% {
    border-radius: 65% 35% 49% 51% / 40% 60%;
  }

  100% {
    border-radius: 60% 40% 50% 50% / 60% 50% 50% 40%;
  }
}

.tech {
  grid-template-rows: 1fr;
  grid-template-columns: repeat(auto-fit, minmax(5.5rem, 1fr));
  justify-content: center;
  justify-items: center;
  gap: 2rem;
  display: grid;
}

@media screen and (min-width: 48em) {
  .tech {
    gap: 3.5rem 3rem;
  }
}

.tech__item {
  filter: grayscale();
  max-width: 8rem;
  transition: filter .3s ease-in-out;
  position: relative;
}

.tech__item a {
  width: 100%;
  display: inline-block;
}

.tech__item .icon {
  aspect-ratio: 1;
  height: unset;
  width: 100%;
}

.tech__item .tooltip {
  z-index: -1;
  color: var(--font-color-darker-2);
  opacity: 0;
  font-family: Kode Mono, monospace;
  font-size: clamp(1rem, .5rem + .4vw, 1.2rem);
  transition: opacity .3s ease-in-out, transform .3s ease-in-out;
  position: absolute;
  top: 110%;
  left: 50%;
  transform: translate(-50%, -50%)scale(0);
}

.tech__item:hover, .tech__item:focus {
  filter: grayscale(0);
}

.tech__item:hover .tooltip, .tech__item:focus .tooltip {
  opacity: 1;
  transform: translate(-50%, -50%)scale(1);
}

.projects {
  grid-template-columns: 1fr;
  gap: 7rem;
  display: grid;
}

@media screen and (min-width: 48em) {
  .projects {
    grid-auto-rows: 1fr;
    gap: 15rem;
    margin-top: 5rem;
  }
}

.project {
  gap: 3rem;
  display: grid;
}

@media screen and (min-width: 48em) {
  .project {
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 12rem;
    display: grid;
  }
}

.project:not(:first-child) {
  position: relative;
}

.project:not(:first-child):before {
  content: "";
  background-color: var(--primary-color);
  opacity: .3;
  border-radius: 500px;
  width: 100%;
  height: 3px;
  position: absolute;
  top: -3.5rem;
  left: 0;
}

@media screen and (min-width: 48em) {
  .project:not(:first-child):before {
    display: none;
  }
}

.project__info-box {
  text-align: center;
  flex-direction: column;
  justify-content: start;
  display: flex;
}

@media screen and (min-width: 48em) {
  .project__info-box {
    text-align: start;
  }
}

.project__name {
  color: var(--font-color-white);
  text-transform: capitalize;
  letter-spacing: 1px;
  margin-bottom: .75em;
  font-size: clamp(2.2rem, 1rem + 1.5vw, 3rem);
  font-weight: 500;
}

.project__techs {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: .75em;
  display: flex;
}

@media screen and (min-width: 48em) {
  .project__techs {
    justify-content: start;
    gap: 1em;
  }
}

.project__techs li {
  color: var(--font-color-white);
  padding: 1em 2em;
  font-size: clamp(1rem, .5rem + .4vw, 1.4rem);
  position: relative;
}

.project__techs li:before {
  content: "";
  z-index: -1;
  background-color: var(--primary-color);
  opacity: .25;
  border-radius: 500px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.project__desc {
  color: var(--font-color-darker);
  margin: 1em 0;
  line-height: 1.5;
}

.project__links-box {
  justify-content: space-evenly;
  gap: 1em;
  display: flex;
}

@media screen and (min-width: 48em) {
  .project__links-box {
    justify-content: start;
    gap: 3em;
    margin-top: auto;
  }
}

.project__link {
  width: max-content;
}

.project__img-box {
  justify-content: start;
  align-items: end;
  display: flex;
  position: relative;
  overflow: hidden;
}

.project__img-box img {
  object-fit: cover;
  object-position: center;
}

@media screen and (min-width: 48em) {
  .project__img-box:before {
    content: "";
    z-index: -1;
    border: 2px solid var(--primary-color);
    opacity: .3;
    width: 90%;
    height: 90%;
    transition: transform .3s ease-in-out;
    position: absolute;
    top: 2%;
  }

  .project__img-box > picture, .project__img-box > img {
    filter: grayscale(100);
    min-width: 95%;
    max-width: 95%;
    min-height: 95%;
    max-height: 95%;
    margin-top: 1.5em;
    transition: filter .3s ease-in-out;
    box-shadow: 0 0 5px #ffffff1a;
  }

  .project__img-box:hover:before {
    transform: scale(.95);
  }

  .project__img-box:hover > picture, .project__img-box:hover > img {
    filter: grayscale(0);
  }

  .project {
    --perspective: 1200px;
    --rotateX: -2deg;
    --rotateY: 7deg;
    --scale: 1.05;
    transform-style: preserve-3d;
    perspective: var(--perspective);
    transition-property: transform, scale;
  }

  .project:nth-child(2n) .project__img-box {
    transform: perspective(var(--perspective)) rotateX(var(--rotateX)) rotateY(var(--rotateY)) scale3d(var(--scale), var(--scale), 1);
    order: -1;
    justify-content: end;
    margin-left: 2.5rem;
  }

  .project:nth-child(2n) .project__img-box:before {
    left: 2%;
    right: unset;
  }

  .project:nth-child(odd) .project__img-box {
    transform: perspective(var(--perspective)) rotateX(var(--rotateX)) rotateY(calc(var(--rotateY) * -1)) scale3d(var(--scale), var(--scale), 1);
    order: 1;
    justify-content: start;
    margin-right: 2.5rem;
  }

  .project:nth-child(odd) .project__img-box:before {
    right: 2%;
    left: unset;
  }
}

.contactme {
  background-color: #171717;
  border-radius: 10px;
  padding: 2rem;
}

@media screen and (min-width: 48em) {
  .contactme {
    grid-template-columns: 1fr 1fr;
    padding: 2rem 6rem;
    display: grid;
  }
}

.contactme__title {
  color: var(--primary-color);
  margin-bottom: 1em;
  font-size: clamp(2.1rem, 1rem + 1.5vw, 3rem);
  font-weight: 400;
  line-height: 1.4;
  display: inline-block;
}

.contactme__title .icon {
  position: relative;
  transform: translate(-10%, 10%);
}

.contactme__links {
  flex-direction: column;
  align-items: start;
  gap: 1.2rem;
  display: flex;
}

@media screen and (min-width: 48em) {
  .contactme__links {
    gap: 2rem;
  }
}

.contactme__links li {
  transition: transform .3s ease-in-out;
}

.contactme__link {
  opacity: .8;
  align-items: center;
  gap: 1.5rem;
  font-size: clamp(1.2rem, .6rem + .6vw, 1.6rem);
  font-weight: 400;
  display: flex;
}

.contactme__link .icon {
  transition: color .3s ease-in-out;
}

.contactme__title:hover + .contactme__links li, .contactme__links li:hover, .contactme__links li:focus-within {
  transform: translateX(5px);
}

.contactme__title:hover + .contactme__links li .icon, .contactme__links li:hover .icon, .contactme__links li:focus-within .icon {
  color: var(--primary-color);
}

.form {
  flex-direction: column;
  gap: 2.5rem;
  margin-top: 4rem;
  padding: 2rem 0;
  display: flex;
  position: relative;
}

.form:before {
  content: "";
  background-color: var(--primary-color);
  height: 1px;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
}

.form:after {
  content: "OR";
  background-color: #171717;
  padding: 0 10px;
  font-size: 1.2rem;
  position: absolute;
  top: -1rem;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

@media screen and (min-width: 48em) {
  .form {
    margin-top: unset;
    gap: 3.5rem;
  }

  .form:before, .form:after {
    all: initial;
  }
}

.form__row {
  flex-direction: column;
  gap: .5em;
  display: flex;
}

.form__row:focus-within .form__label {
  color: var(--primary-color);
}

.form__label {
  color: var(--font-color-darker);
  text-transform: capitalize;
  font-size: clamp(1.3rem, .7rem + 1vw, 1.6rem);
  transition: color .3s ease-in-out;
}

.form__input {
  color: var(--font-color-darker);
  resize: vertical;
  background-color: #0000;
  border: 1px solid #ffffff4d;
  border-radius: 10px;
  outline: #0000;
  padding: 1em;
  font-family: inherit;
  font-size: clamp(1.3rem, .7rem + 1vw, 1.6rem);
  font-weight: 500;
  transition: border-color .3s ease-in-out;
}

.form__input:focus, .form__input:focus-visible {
  outline-color: var(--primary-color);
  border-color: var(--primary-color);
}

.form.form-message {
  visibility: hidden;
  pointer-events: none;
}

.form input:user-invalid {
  border-color: #e03131;
  outline-color: #e03131;
}

.form__row {
  position: relative;
}

.form__row .error-msg {
  color: #e03131;
  padding-top: .5rem;
  font-size: 1.1em;
  font-weight: 400;
  position: absolute;
  top: 100%;
}

@media screen and (min-width: 48em) {
  .form__row .error-msg {
    font-size: 1.2em;
  }
}

.form .message, .form .message--error, .form .message--success {
  visibility: visible;
  text-align: center;
  width: 100%;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form .message .title, .form .message--error .title, .form .message--success .title {
  text-transform: uppercase;
  font-size: clamp(3.4rem, 1.5rem + 2vw, 5.5rem);
}

.form .message .desc, .form .message--error .desc, .form .message--success .desc {
  color: var(--font-color-darker);
  margin: 2.5rem 0 2rem;
}

.form .message .icon, .form .message--error .icon, .form .message--success .icon {
  font-size: 4em;
}

.form .message--success .title {
  color: var(--primary-color);
}

.form .message--success .icon {
  fill: var(--primary-color);
}

.form .message--error .title {
  color: #e03131;
}

.form .message--error .icon {
  fill: #e03131;
}

.loading {
  pointer-events: none;
  opacity: .5;
  position: relative;
}

.loading:after {
  content: "";
  z-index: 10;
  border: 6px solid #fff6;
  border-right-color: #ffffffc7;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: .6s linear infinite rotateSingleLoading;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(0);
}

@keyframes rotateSingleLoading {
  0% {
    transform: translate(-50%, -50%)rotate(0);
  }

  100% {
    transform: translate(-50%, -50%)rotate(360deg);
  }
}

.footer {
  background-color: #171717;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5em;
  padding: 1.5rem 1em;
  display: flex;
  position: relative;
}

.footer__logo {
  margin-left: .5em;
  font-size: clamp(2rem, 1rem + 1.5vw, 3.5rem);
  line-height: .8;
}

.footer p {
  color: #ffffff46;
  font-size: clamp(1.2rem, .3rem + .6vw, 1.4rem);
}

.footer__links {
  align-items: center;
  gap: .75em;
  margin: 1.5rem 0 2rem;
  font-size: clamp(2rem, 1rem + 1vw, 3rem);
  display: flex;
}

.footer__link {
  box-sizing: content-box;
  z-index: 0;
  aspect-ratio: 1;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 2em;
  transition: background-color .3s ease-in-out;
  display: flex;
  position: relative;
}

.footer__link:before {
  content: "";
  z-index: -1;
  background-color: var(--primary-color);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.footer__link:after {
  content: "";
  z-index: -2;
  background-color: #9675fa;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  transition: transform .3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.footer__link span {
  text-transform: uppercase;
  opacity: 0;
  font-size: .3em;
  transition: opacity .3s ease-in-out;
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.footer__link .icon {
  width: 1em;
  height: 1em;
  transition: transform .3s ease-in-out, opacity .3s ease-in-out;
}

.footer__link:hover:after, .footer__link:focus:after {
  transform: scale(1.3);
}

.footer__link:hover span, .footer__link:focus span {
  opacity: .7;
}

.footer__link:hover .icon, .footer__link:focus .icon {
  opacity: .8;
  transform: scale(.8)translateY(-25%);
}

/*# sourceMappingURL=main.min.9be1af9e.css.map */
