@use '../abstracts/breakpoints' as b;
@use '../abstracts/colors' as c;

$mouseHeight: 40px;
$mouseWidth: 20px;

$mouseColor: rgba(255, 255, 255, 0.5);
$mouseWheelColor: $mouseColor;

$hoverTransition: 0.2s ease-in-out;
$mouseHoverColor: c.$primary-color;
$mouseWheelHoverColor: $mouseHoverColor;

.mouse-scroll {
  display: none;

  @media screen and (min-width: b.$medium) {
    position: absolute;
    bottom: 3%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    cursor: pointer;
  }

  .text {
    color: rgba(255, 255, 255, 0.2);
    text-transform: uppercase;
    font-size: 1rem;
    font-family: 'Kode Mono', sans-serif;
    letter-spacing: 1px;
  }

  .mouse-outer {
    position: relative;
    display: inline-block;

    height: $mouseHeight;
    width: $mouseWidth;

    border: 2px solid $mouseColor;
    border-radius: $mouseHeight;

    transition: border-color $hoverTransition;

    .mouse-wheel {
      $wheelWidth: ceil(calc($mouseWidth / 6));
      $halfWheelWidth: calc($mouseWidth / 12);

      position: absolute;
      top: 10%;
      left: 50%;
      margin-left: -$halfWheelWidth;

      height: calc($mouseHeight / 4);
      width: $wheelWidth;
      background-color: $mouseWheelColor;

      border-radius: $wheelWidth;

      transition: background-color $hoverTransition;

      -webkit-animation: scroll 1.4s infinite linear;
      -moz-animation: scroll 1.4s infinite linear;
      -o-animation: scroll 1.4s infinite linear;
      animation: scroll 1.4s infinite linear;
    }

    &:hover {
      border-color: $mouseHoverColor;

      .mouse-wheel {
        background-color: $mouseWheelHoverColor;
      }
    }
  }
}

@keyframes scroll {
  0% {
    transform: scaleY(0.5) translateY(0);
  }
  20% {
    transform: scaleY(1) translateY(calc($mouseHeight / 8));
  }
  60% {
    transform: scaleY(0.5) translateY(15px);
  }
  to {
    transform: scaleY(0.5) translateY(0);
  }
}
