@use '../abstracts/colors' as c;
@use '../abstracts/breakpoints' as b;
@use '../abstracts/variables' as v;

$btn-radious: 3px;
$btn-color: #eee;

$btn-font-family: 'Roboto', sans-serif;
$btn-font-weight: 500;
$btn-primary-bgc: c.$primary-color;

.btn {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75em;
  padding: 0.7em 1.2em;
  color: $btn-color;
  font-size: clamp(1.3rem, 0.7rem + 0.5vw, 1.5rem);
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  text-transform: capitalize;
  cursor: pointer;
  transition: 0.5s;

  .icon {
    width: 1.5em;
    aspect-ratio: 1/1;
    fill: currentColor;
  }

  &--primary {
    @extend .btn;
    background-color: $btn-primary-bgc;
    border-radius: $btn-radious;
    outline: transparent;

    &:before,
    &:after {
      box-sizing: inherit;
      content: '';
      position: absolute;
      z-index: -1;
    }

    &:before,
    &:after {
      border: 2px solid transparent;
      width: 0;
      height: 0;
    }
    // right & top border
    &:before {
      top: 0;
      left: 0;
    }
    // left & bottom border
    &:after {
      bottom: 0;
      right: 0;
    }
    &:hover,
    &:focus {
      color: $btn-primary-bgc;
      background: transparent;

      &:before,
      &:after {
        width: 100%;
        height: 100%;
      }
      &:before {
        border-top-color: $btn-primary-bgc;
        border-right-color: $btn-primary-bgc;
        transition:
          width 0.25s ease-out,
          height 0.25s ease-out 0.25s; // wait for width
      }
      &:after {
        border-bottom-color: $btn-primary-bgc;
        border-left-color: $btn-primary-bgc;
        transition:
          border-color 0s ease-out 0.5s,
          // wait for :before
          width 0.25s ease-out 0.5s,
          height 0.25s ease-out 0.75s; // wait for width
      }
    }
  }

  &--secondary {
    @extend .btn;

    &::before {
      content: '';
      position: absolute;
      top: 85%;
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
      width: calc(105% - 1.2em * 2);
      margin: 0 auto;
      background-color: c.$primary-color;
      transition:
        transform v.$transition-duration v.$transition-function,
        box-shadow v.$transition-duration v.$transition-function;
    }
    &:hover {
      &::before {
        transform: translateX(-50%) scaleY(2) scaleX(1.2);
        box-shadow:
          0 -5px 10px c.$primary-color,
          0 -10px 15px c.$primary-color,
          0 -15px 25px c.$primary-color;
      }
    }
  }
}
