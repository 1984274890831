.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.wrapper-12 {
  margin: 0 auto;
  width: 100%;
  max-width: 120rem;
}
.wrapper-14 {
  margin: 0 auto;
  width: 100%;
  max-width: 140rem;
}

.scroll-margin {
  scroll-margin: 3rem;
}
.scroll-margin-large {
  scroll-margin: 25rem;
}
